import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';

import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    private readonly http = inject(HttpClient);
    private readonly document = inject(DOCUMENT);
    private readonly platformId = inject(PLATFORM_ID);
    // https://ngneat.github.io/transloco/docs/ssr-support
    getTranslation(lang: string) {
        if (isPlatformBrowser(this.platformId)) {
            return this.http.get<Translation>(`${this.document.baseURI}assets/i18n/${lang}.json`);
        } else {
            return this.http.get<Translation>(`${environment.endpoints.frontend}/assets/i18n/${lang}.json`);
        }
    }
}
