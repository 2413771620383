import { enableMapSet } from 'immer';

import { bootstrapApplication } from '@angular/platform-browser';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { Logger } from './app/helper/logger';

if (process.env['NODE_ENV'] === 'development') {
    Logger.setLogLevel('DEBUG');
}

enableMapSet();

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
