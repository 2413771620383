import { concat, first, interval, tap } from 'rxjs';

import { ApplicationRef, Injectable, afterNextRender } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable({
    providedIn: 'root'
})
export class SwUpdateService {
    constructor(appRef: ApplicationRef, updates: SwUpdate) {
        // Allow the app to stabilize first, before starting
        // polling for updates with `interval()`.
        const appIsStable$ = appRef.isStable.pipe(
            tap(isStable => console.log('Stable', isStable)),
            first(isStable => isStable === true)
        );
        const every10min$ = interval(10 * 60 * 1000);
        const every10MinOnceAppIsStable$ = concat(appIsStable$, every10min$);
        afterNextRender(() => {
            every10MinOnceAppIsStable$.subscribe(async () => {
                try {
                    console.log('Check for updates');
                    const updateFound = await updates.checkForUpdate();
                    console.log(updateFound ? 'A new version is available.' : 'Already on the latest version.');
                    if (updateFound) {
                        document.location.reload();
                    }
                } catch (err) {
                    console.error('Failed to check for updates:', err);
                }
            });
        });
    }
}
