import {
  LogLevelDesc,
  debug,
  error,
  info,
  log,
  setLevel,
  trace,
  warn,
} from 'loglevel';

/**
 * Wrapper for the loglevel library
 */
export class Logger {
  /**
   * Captures the stack and returns it
   * @returns the current stack
   */
  static getStackTrace() {
    const err = new Error();
    let formattedStack = 'No Stack Available for this browser';

    // format the error
    if (err.stack) {
      formattedStack = err.stack.toString().replace(/Error/g, '');
    }

    return formattedStack;
  }

  /**
   * Set the log level
   */
  static setLogLevel(
    level: LogLevelDesc,
    persist: boolean | undefined = undefined
  ) {
    setLevel(level, persist);
  }

  /**
   * The standard logging output
   * @param message - the message to be logged
   */
  static log(...message: unknown[]) {
    log('%c[LOG]%c', 'color: #38b2ac', 'color: #000000', message);
  }

  /**
   * The standard logging output
   * @param message - the message to be logged
   */
  static info(...message: unknown[]) {
    info('%c[INFO]%c', 'color: #3182ce', 'color: #000000', message);
  }

  /**
   * The standard logging output
   * @param message - the message to be logged
   */
  static error(...message: unknown[]) {
    error('%c[ERROR]%c', 'color: #e53e3e', 'color: #000000', message);
  }

  /**
   * The standard logging output
   * @param message - the message to be logged
   */
  static warn(...message: unknown[]) {
    warn('%c[WARN]%c', 'color: #ed8936', 'color: #000000', message);
  }

  /**
   * The standard logging output
   * @param message - the message to be logged
   */
  static debug(...message: unknown[]) {
    debug('%c[DEBUG]%c', 'color: #38b2ac', 'color: #000000', message);
  }

  /**
   * The standard logging output
   * @param message - the message to be logged
   */
  static trace(...message: unknown[]) {
    trace('%c[TRACE]%c', 'color: #a0aec0', 'color: #000000', message);
  }
}
