import { CookiebotService } from './cookiebot.service';

/**
 *
 */
export function cookiebotFactory(cookieBotService: CookiebotService): () => Promise<void> {
    return (): Promise<void> => {
        return cookieBotService.init();
    };
}
