[
  "// Matches the first entry in route, more specific need to come first",
  {
    "route": "^/contact",
    "title": "Contact CGI.Backgrounds",
    "description": "How to contact CGI.Backgrounds",
    "image": "https://www.cgibackgrounds.com/assets/images/contact.jpg"
  },
  {
    "route": "^/blog$",
    "title": "CGI.Backgrounds Blog - News, Tutorials, and Resources for the CGI Industry",
    "description": "On our blog, CGI.Backgrounds shares CGI industry news, tutorials, HDRi Map and Backplate resources, and more",
    "image": "https://www.cgibackgrounds.com/assets/images/pricing/cover.jpg"
  },
  {
    "route": "^/auth/sign-in",
    "title": "Sign into Your CGI.Backgrounds Account",
    "description": "Sign into your account on CGI.Backgrounds, or create a new account",
    "image": "https://www.cgibackgrounds.com/assets/images/pricing/cover.jpg"
  },
  {
    "route": "^/auth/sign-up",
    "title": "Create a Free CGI.Backgrounds Account",
    "description": "Create a free account on CGI.Backgrounds and begin downloading HDRi Map and Backplate comps, or subscribe to our services",
    "image": "https://www.cgibackgrounds.com/assets/images/pricing/cover.jpg"
  },
  {
    "route": "^/auth/forgot-password",
    "title": "Reset Your CGI.Backgrounds Password",
    "description": "Reset your password for CGI.Backgrounds",
    "image": "https://www.cgibackgrounds.com/assets/images/pricing/cover.jpg"
  },
  {
    "route": "^/lightboxes",
    "title": "Manage Your CGI.Backgrounds Lightboxes",
    "description": "Manage and create lightboxes",
    "image": "https://www.cgibackgrounds.com/assets/images/titles/ligthboxes.jpg"
  },
  {
    "route": "^/newsletter",
    "title": "Subscribe to the CGI.Backgrounds Newsletter",
    "description": "Join our newsletter for industry updates, blog posts, and company news",
    "image": "https://www.cgibackgrounds.com/assets/images/titles/newsletter.jpg"
  },
  {
    "route": "^/subscribe-newsletter",
    "title": "Subscribe to the CGI.Backgrounds Newsletter",
    "description": "Join our newsletter for industry updates, blog posts, and company news",
    "image": "https://www.cgibackgrounds.com/assets/images/titles/newsletter.jpg"
  },
  {
    "route": "^/search/subject/(?<location>[A-Za-z-_0-9]+)",
    "title": "%location% HDRi Maps and Backplates - CGI.Backgrounds",
    "description": "%location% Premium HDRi maps and backplates",
    "image": "https://www.cgibackgrounds.com/assets/images/titles/search.jpg"
  },
  {
    "route": "/search\\?term=(?<term>[a-z]+)",
    "title": "%term% HDRi Maps and Backplates - CGI.Backgrounds",
    "description": "Premium HDRi maps and backplates of %term%",
    "image": "https://www.cgibackgrounds.com/assets/images/titles/search.jpg"
  },
  {
    "route": "/search",
    "title": "Search HDRi Maps and Backplates - CGI.Backgrounds",
    "description": "Discover the Backplates and HDRi Maps",
    "image": "https://www.cgibackgrounds.com/assets/images/titles/search.jpg"
  }
]
