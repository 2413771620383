import { CgibCookiebotConfig } from '@cgib/ui-shared/config/cookiebot.config';

export class CookiebotConfig extends CgibCookiebotConfig {
    blockingMode: 'auto' | 'manual' | string = 'auto';
    cbId = 'aec35fc9-1ed6-413f-9979-c63393dc3d85';
    loadScript = false;
    cdn = 'com';
    culture?: string;
    framework?: string;
    level?: string;
    type?: string;
}
