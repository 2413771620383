import { forkJoin } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';

import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { setUser } from '@sentry/angular-ivy';

import { MixpanelEventEnum } from '@cgib/shared/enums/mixpanel-event.enum';
import { MixpanelService } from '@cgib/ui-shared/modules/mixpanel/mixpanel.service';
import { CookiebotService } from '@cgib/ui-shared/services/cookiebot/cookiebot.service';
import { QuotaService } from '@cgib/ui-shared/services/quota.service';
import { SwUpdateService } from '@cgib/ui-shared/services/sw-update.service';
import { UserService } from '@cgib/ui-shared/services/user.service';

import { environment } from '../environments/environment';
import { UploadFileSelectComponent } from './components/upload-file-select/upload-file-select.component';
import ROUTE_CONFIG from './route-configuration.json';
import { SeoService } from './services/seo.service';
import { SessionService } from './shared/services/session/session.service';

@Component({
    selector: 'cgib-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [CommonModule, UploadFileSelectComponent, RouterOutlet]
})
export class AppComponent implements OnInit {
    private readonly document = inject(DOCUMENT);
    private readonly userService = inject(UserService);
    private readonly quotaService = inject(QuotaService);
    private readonly swUpdateService = inject(SwUpdateService);

    constructor(
        private readonly mixpanelService: MixpanelService,
        private readonly sessionService: SessionService,
        private readonly translocoService: TranslocoService,
        private readonly router: Router,
        private readonly seoService: SeoService,
        private readonly cookiebotService: CookiebotService
    ) {
        const params = new URLSearchParams(this.document.location.search);
        this.seoService.createLinkForCanonicalURL(
            environment.endpoints.frontend +
                this.document.location.pathname +
                (params.has('page') ? `?page=${params.get('page') ?? 1}` : '')
        );
        this.initLanguage();
        this.cookiebotService.onServiceReady$
            .pipe(
                filter(data => !!data),
                switchMap(() => this.cookiebotService.onLoadCallback$),
                tap(() => {
                    if (this.cookiebotService.cookiebot.changed) {
                        document.location.reload();
                    }
                }),
                takeUntilDestroyed()
            )
            .subscribe();

        this.cookiebotService.onServiceReady$
            .pipe(
                filter(data => !!data),
                tap(() => {
                    if (this.cookiebotService.cookiebot?.consent) {
                        if (this.cookiebotService.cookiebot?.consent?.marketing) {
                            this.initMarketing();
                        }
                        if (this.cookiebotService.cookiebot.consent.statistics) {
                            this.mixpanelService.init();
                        }
                    }
                }),
                takeUntilDestroyed()
            )
            .subscribe();

        forkJoin([this.cookiebotService.onServiceReady$, this.userService.isAuthenticated$()])
            .pipe(
                filter(([ready, authenticated]) => ready && authenticated),
                take(1),
                switchMap(() =>
                    forkJoin([
                        this.userService.getUserEmail$().pipe(
                            filter(userInfo => !!userInfo),
                            take(1)
                        ),
                        this.userService.getUserId$().pipe(
                            filter(userInfo => !!userInfo),
                            take(1)
                        ),
                        this.userService.getGravatarUrl$(512).pipe(
                            filter(url => !!url),
                            take(1)
                        ),
                        this.quotaService.getQuota$().pipe(
                            filter(quota => !!quota),
                            take(1)
                        )
                    ])
                ),
                tap(([mail, id, gravatarUrl, quota]) => {
                    //this.cookiebotService.cookiebot.submitCustomConsent(true, true, true);

                    this.initMarketing();
                    if (mail && id) {
                        // If a user signes in he consents to mixpanel tracking
                        this.mixpanelService.init();
                        this.mixpanelService.identify(id);
                        this.mixpanelService.setEmail(mail);
                        this.mixpanelService.setSubscription(quota?.activeSubscription?.subscriptionName);

                        setUser({
                            username: id,
                            email: mail
                        });
                    }

                    if (gravatarUrl) {
                        this.mixpanelService.setAvatar(gravatarUrl);
                    }
                }),
                takeUntilDestroyed()
            )
            .subscribe();
    }

    ngOnInit() {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
            const config = ROUTE_CONFIG.map(
                routeConfig => {
                    if (typeof routeConfig !== 'string') {
                        const val = new RegExp(routeConfig.route).exec(this.router.url);
                        if (val?.length === 1) {
                            return routeConfig;
                        }
                        if ((val?.length ?? 0) > 1) {
                            const replaceKey = Object.keys(val?.groups ?? {})?.[0];
                            if (replaceKey) {
                                const replaceValue = val?.groups?.[replaceKey].toUpperCase();
                                if (replaceValue) {
                                    routeConfig.title = routeConfig.title.replace('%' + replaceKey + '%', replaceValue);
                                    routeConfig.description = routeConfig.description.replace(
                                        '%' + replaceKey + '%',
                                        replaceValue
                                    );
                                }
                            }
                            return routeConfig;
                        }
                        return undefined;
                    }
                    return undefined;
                }
                // We need to have comments in json, because of this we need to omit strings
            ).filter(data => !!data)?.[0] as
                | { title: string; route: string; description: string; image: string; keywords?: string[] }
                | undefined;
            if (config) {
                this.seoService.updateTags({
                    title: config.title,
                    ogDescription: config.description,
                    description: config.description,
                    imageUrl: 'https://' + this.document.location.hostname + this.router.url,
                    keywords: config.keywords?.join(', ')
                });

                const params = new URLSearchParams(this.document.location.search);
                this.seoService.createLinkForCanonicalURL(
                    environment.endpoints.frontend +
                        this.document.location.pathname +
                        (params.has('page') ? `?page=${params.get('page') ?? 1}` : '')
                );
            }
        });
    }
    private initMarketing() {
        if (!this.mixpanelService.hasInitalised) {
            this.mixpanelService.init();
            this.mixpanelService.register({
                stage: environment.production ? 'production' : 'development',
                client: 'cgib-website'
            });
            this.mixpanelService.track(MixpanelEventEnum.LAUNCH);
        }
    }

    private initLanguage() {
        // Initialize session with a previous saved state if available
        this.sessionService
            .getSessionConfiguration$()
            .pipe(take(1))
            .subscribe(config => {
                this.translocoService.setActiveLang(config.language);
            });
    }
}
