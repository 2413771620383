import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LightboxFilterPipe } from './lightbox-item-filter.pipe';

@NgModule({
    declarations: [LightboxFilterPipe],
    imports: [CommonModule],
    exports: [LightboxFilterPipe],
    providers: [LightboxFilterPipe]
})
export class LightboxFilterModule {}
